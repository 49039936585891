const procedimientosRoutes = [{
    path: '/procedimientos/listar',
    name: 'ListarProcedimientosAdmin',
    component: () =>
        import ('@/modules/procedimientos/views/ProcedimientosAdmin'),
    meta: { requiresAuth: true, domain: 'ProcedimientosAdmin', title: 'Listar procedimientos', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
{
    path: '/procedimientos/nueva',
    name: 'CrearProcedimiento',
    component: () =>
        import ('@/modules/procedimientos/views/CrearProcedimiento'),
    meta: { requiresAuth: true, domain: 'ProcedimientosAdmin', title: 'Crear procedimiento', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
{
    path: '/procedimientos/ver/:id',
    name: 'VerProcedimiento',
    component: () =>
        import ('@/modules/procedimientos/views/VerProcedimiento'),
    meta: { requiresAuth: true, domain: 'ProcedimientosAdmin', title: 'Ver procedimiento', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
// {
//     path: '/procedimientos/mostrar/:uuid',
//     name: 'MostrarProcedimiento',
//     component: () =>
//         import ('@/modules/procedimientos/views/MostrarProcedimiento'),
//     meta: { requiresAuth: true, domain: 'Inicio', title: 'Mostrar procedimiento', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
// },
{
    path: '/procedimientos/editar/:id',
    name: 'EditarProcedimiento',
    component: () =>
        import ('@/modules/procedimientos/views/EditarProcedimiento'),
    meta: { requiresAuth: true, domain: 'ProcedimientosAdmin', title: 'Editar procedimiento', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
]

export default procedimientosRoutes
