const gerenciasRoutes = [{
        path: '/gerencias/listar',
        name: 'ListarGerencias',
        component: () =>
            import ('@/modules/gerencias/views/ListarGerencias'),
        meta: { requiresAuth: true, domain: 'Gerencias', title: 'Listar gerencias', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/gerencias/ver/:id',
        name: 'VerGerencia',
        component: () =>
            import ('@/modules/gerencias/views/VerGerencia'),
        meta: { requiresAuth: true, domain: 'Gerencias', title: 'Ver gerencias', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    }
]

export default gerenciasRoutes
