const categoriasRoutes = [{
        path: '/categorias/listar',
        name: 'ListarCategorias',
        component: () =>
            import ('@/modules/categorias/views/ListarCategorias'),
        meta: { requiresAuth: true, domain: 'Categorias', title: 'Listar categorías', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/categorias/ver/:id',
        name: 'VerCategoria',
        component: () =>
            import ('@/modules/categorias/views/VerCategoria'),
        meta: { requiresAuth: true, domain: 'Categorias', title: 'Ver categoría', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    }
]

export default categoriasRoutes
