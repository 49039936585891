import store from '@/store';
import axios from 'axios';
//SI HAY UN CAMBIO EN EL TOKEN DEL AUTH ENTONCES LO SETEA COMO DEFAULT EN LOS HEADERS DE AXIOS
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + mutation.payload;
                localStorage.setItem('token', mutation.payload);
            } else {
                axios.defaults.headers.common['Authorization'] = null;
                localStorage.removeItem('token');
            }
            break;
    }
});