import Vue from 'vue'
import VueRouter from 'vue-router'
import PersonasRoutes from '@/modules/personas/routes/personas'
import AreasRoutes from '@/modules/areas/routes/areas'
import GerenciasRoutes from '@/modules/gerencias/routes/gerencias'
import CategoriasRoutes from '@/modules/categorias/routes/categorias'
import ObrasSocialesRoutes from '@/modules/obras_sociales/routes/obras_sociales'
import SucursalesRoutes from '@/modules/sucursales/routes/sucursales'
import LiquidacionesRoutes from '@/modules/liquidaciones/routes/liquidaciones'
import licenciasRoutes from '@/modules/licencias/routes/licencias'
import recibosRoutes from '@/modules/recibos/routes/recibos'
import noticiasRoutes from '@/modules/noticias/routes/noticias'
import procedimientosRoutes from '../modules/procedimientos/routes/procedimientos'
import parteDiarioRoutes from '../modules/parte_diario/routes/parteDiario'
import guardiasRoutes from '../modules/guardias/routes/guardias'
import permisoGuardiaRoutes from '../modules/permiso_guardia/routes/permiso_guardia'
import permisoParteRoutes from '../modules/permiso_parte/routes/permiso_parte'
import percepcionesRoutes from "@/modules/percepcionesAsignacionFamiliar/routes/percepciones";

Vue.use(VueRouter)


const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('@/views/Login'),
        meta: { title: 'Iniciar sesión' }
    },
    {
        path: '/validar-datos',
        name: 'ValidarDatos',
        component: () =>
            import ('@/modules/personas/views/ValidarDatos'),
        meta: { requiresAuth: true, domain: 'ValidarDatos', title: 'Validar datos', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
    },
    {
        path: '/',
        component: () =>
            import ('@/views/Index'),
        children: [
            ...noticiasRoutes,
            ...recibosRoutes,
            ...LiquidacionesRoutes,
            ...licenciasRoutes,
            ...PersonasRoutes,
            ...AreasRoutes,
            ...GerenciasRoutes,
            ...CategoriasRoutes,
            ...ObrasSocialesRoutes,
            ...SucursalesRoutes,
            ...procedimientosRoutes,
            ...parteDiarioRoutes,
            ...guardiasRoutes,
            ...permisoGuardiaRoutes,
            ...permisoParteRoutes,
            ...percepcionesRoutes,
            {
                path: '/formularios',
                name: 'Formularios',
                component: () =>
                    import ('@/modules/formularios/views/Formularios'),
                meta: { requiresAuth: true, domain: 'Formularios', title: 'Formularios', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true}
            },
            {
                path: '/procedimientos',
                name: 'Procedimientos',
                component: () =>
                    import ('@/modules/procedimientos/views/Procedimientos'),
                meta: { requiresAuth: true, domain: 'Procedimientos', title: 'Procedimientos', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true}
            },
            {
                path: '/',
                name: 'Inicio',
                component: () =>
                    import ('@/views/Inicio'),
                meta: { requiresAuth: true, domain: 'Inicio', title: 'Inicio' }
            }
        ],
        meta: { requiresAuth: true }
    }

]
const router = new VueRouter({
    mode: 'history',
    routes,
    linkExactActiveClass: 'active'
})

export default router
