const personasRoutes = [{
        path: '/personas/listar',
        name: 'ListarPersonas',
        component: () =>
            import ('@/modules/personas/views/ListarPersona'),
        meta: { requiresAuth: true, domain: 'Personas', title: 'Listar personas', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/personas/nueva',
        name: 'NuevaPersona',
        component: () =>
            import ('@/modules/personas/views/NuevaPersona'),
        meta: { requiresAuth: true, domain: 'Personas', title: 'Nueva persona', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/personas/ver/:id',
        name: 'VerPersona',
        component: () =>
            import ('@/modules/personas/views/VerPersona'),
        meta: { requiresAuth: true, domain: 'Personas', title: 'Ver persona', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/personas/editar/:id',
        name: 'EditarPersona',
        component: () =>
            import ('@/modules/personas/views/EditarPersona'),
        meta: { requiresAuth: true, domain: 'Personas', title: 'Editar persona', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/mis-datos',
        name: 'MisDatos',
        component: () =>
            import ('@/modules/personas/views/MisDatos'),
        meta: { requiresAuth: true, title: 'Mis datos', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true }
    },
    {
        path: '/cambiar-password',
        name: 'CambiarPassword',
        component: () =>
            import ('@/modules/personas/views/CambiarPassword'),
        meta: { requiresAuth: true, title: 'Cambiar contraseña', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true }
    },
]

export default personasRoutes
