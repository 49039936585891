import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'
import IdleVue from "idle-vue"
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar';

import "quill/dist/quill.core.css";

import '@/assets/css/app.css'
import '@/assets/css/adc.css'
import './global-components.js'
import filters from './plugins/filters.js'
import './registerServiceWorker'


localStorage.setItem("modal", 0);
require('@/store/subscribe.js');
require('./axios.js');
// require('./plugins/notifications.js');
require('@/router/middlewares/auth.js');
const eventsHub = new Vue();

Vue.config.productionTip = false
Vue.use(filters);
Vue.use(Vuelidate);
Vue.use(VCalendar)
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 59 * 1000, // 3 seconds
    startAtIdle: false
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
