const guardiasRoutes = [
    {
        path: '/guardias',
        name: 'ListGuardiasPorDia',
        component: () =>
            import ('@/modules/guardias/views/ListGuardiasPorDia'),
        meta: { requiresAuth: true, domain: 'GuardiasCalendar', title: 'Calendario de guardias', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true }
    },
    ]

    export default guardiasRoutes
