import moment from 'moment'
const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.toCuil = (value) => {
            if (!value) return ''
            value = value.toString()
            if (value.length == 11) {
                let values = value.split("");
                values.splice(2, 0, "-");
                values.splice(11, 0, "-");
                return values.join("");
            }
            return value;
        }
        Vue.prototype.normalDate = (value) => {
            let date = moment(value)
            return date.format('DD/MM/YYYY');
        }
    },
}
export default MyPlugin;