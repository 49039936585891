const areasRoutes = [{
        path: '/areas/listar',
        name: 'ListarAreas',
        component: () =>
            import ('@/modules/areas/views/ListarAreas'),
        meta: { requiresAuth: true, domain: 'Areas', title: 'Listar áreas', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
    {
        path: '/areas/ver/:id',
        name: 'VerArea',
        component: () =>
            import ('@/modules/areas/views/VerArea'),
        meta: { requiresAuth: true, domain: 'Areas', title: 'Ver área', valid: ['RRHH', 'dev'], blockOnDisabled: true }
    },
]

export default areasRoutes
