const permisoGuardiaRoutes = [
  {
    path: '/permiso-guardia',
    name: 'ListPermisoGuardia',
    component: () =>
      import('@/modules/permiso_guardia/views/ListPermisoGuardia'),
    meta: {
      requiresAuth: true,
      domain: 'PermisoGuardia',
      title: 'Listar permisos de guardias',
      valid: ['RRHH', 'dev'], blockOnDisabled: true,
    },
  },
];

export default permisoGuardiaRoutes;
