const percepcionesRoutes = [
  {
    path: '/asignaciones-familiares/listar',
    name: 'ListarPercepciones',
    component: () =>
      import ('@/modules/percepcionesAsignacionFamiliar/views/ListarAsignacionesFamiliares.vue'),
    meta: {
      requiresAuth: true,
      domain: 'Percepciones',
      title: 'Listar asignaciones familiares',
      valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
      blockOnDisabled: true,
    }
  },
  {
    path: '/asignaciones-familiares/:id/ver',
    name: 'VerAsignacionesFamiliares',
    component: () =>
      import ('@/modules/percepcionesAsignacionFamiliar/views/VerAsignacionFamiliar.vue'),
    meta: {
      requiresAuth: true,
      domain: 'Percepciones',
      title: 'Ver asignaciones familiares',
      valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
      blockOnDisabled: true,
    }
  },
  {
    path: '/percepciones/maternidad/crear',
    name: 'CrearPercepcionMaternidad',
    component: () =>
      import ('@/modules/percepcionesAsignacionFamiliar/views/CrearPercepcionMaternidad.vue'),
    meta: {
      requiresAuth: true,
      domain: 'Percepciones',
      title: 'Crear percepcion maternidad',
      valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
      blockOnDisabled: true,
    }
  },
  {
    path: '/percepciones/cargas-familia/crear',
    name: 'CrearPercepcionCargasFamilia',
    component: () =>
      import ('@/modules/percepcionesAsignacionFamiliar/views/CrearPercepcionCargasFamilia.vue'),
    meta: {
      requiresAuth: true,
      domain: 'Percepciones',
      title: 'Crear DDJJ percepcion cargas familia',
      valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
      blockOnDisabled: true,
    }
  },
  {
    path: '/percepciones/cargas-familia/:id/edit',
    name: 'EditarPercepcionCargasFamilia',
    component: () =>
      import ('@/modules/percepcionesAsignacionFamiliar/views/EditarPercepcionCargasFamilia.vue'),
    meta: {
      requiresAuth: true,
      domain: 'Percepciones',
      title: 'Rectificar DDJJ percepcion cargas familia',
      valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
      blockOnDisabled: true,
    }
  }
]

export default percepcionesRoutes
