const noticiasRoutes = [{
    path: '/noticias/listar',
    name: 'ListarNoticias',
    component: () =>
        import ('@/modules/noticias/views/ListarNoticias'),
    meta: { requiresAuth: true, domain: 'Noticias', title: 'Listar noticias', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
{
    path: '/noticias/nueva',
    name: 'CrearNoticia',
    component: () =>
        import ('@/modules/noticias/views/CrearNoticia'),
    meta: { requiresAuth: true, domain: 'Noticias', title: 'Crear noticia', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
{
    path: '/noticias/ver/:id',
    name: 'VerNoticia',
    component: () =>
        import ('@/modules/noticias/views/VerNoticia'),
    meta: { requiresAuth: true, domain: 'Noticias', title: 'Ver noticia', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
{
    path: '/noticias/mostrar/:uuid',
    name: 'MostrarNoticia',
    component: () =>
        import ('@/modules/noticias/views/MostrarNoticia'),
    meta: { requiresAuth: true, domain: 'Inicio', title: 'Mostrar noticia', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"], blockOnDisabled: true }
},
{
    path: '/noticias/editar/:id',
    name: 'EditarNoticia',
    component: () =>
        import ('@/modules/noticias/views/EditarNoticia'),
    meta: { requiresAuth: true, domain: 'Noticias', title: 'Editar noticia', valid: ['RRHH', 'dev'], blockOnDisabled: true }
},
]

export default noticiasRoutes
