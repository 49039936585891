const sucursalesRoutes = [{
        path: '/sucursales/listar',
        name: 'ListarSucursales',
        component: () =>
            import ('@/modules/sucursales/views/ListarSucursales'),
        meta: { requiresAuth: true, domain: 'Sucursales', title: 'Listar sucursales', valid: ["RRHH", "dev"], blockOnDisabled: true }
    },
    {
        path: '/sucursales/ver/:id',
        name: 'VerSucursal',
        component: () =>
            import ('@/modules/sucursales/views/VerSucursal'),
        meta: { requiresAuth: true, domain: 'Sucursales', title: 'Ver sucursal', valid: ["RRHH", "dev"], blockOnDisabled: true }
    }
]

export default sucursalesRoutes
