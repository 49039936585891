const recibosRoutes = [

    {
        path: '/recibos/mis-recibos',
        name: 'MisRecibos',
        component: () =>
            import ('@/modules/recibos/views/MisRecibos'),
        meta: { requiresAuth: true, domain: 'MisRecibos', title: 'Mis recibos', valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"] }
    },
]

export default recibosRoutes