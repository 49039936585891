import { register } from 'register-service-worker'
import Axios from 'axios'
import Swal from "sweetalert2";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready(reg) {
    if (!("Notification" in window)) {
      alert(
        "No podrás obtener notificaciones del sistema ya que tu navegador no lo soporta."
      );
    } else if (Notification.permission !== "granted") {
      Swal.fire(
        {
        title: "Notificaciones",
        html: `<p>Para recibir notifiaciones cuando estén listos tus recibos o se haya subido información de interes, por favor presiona "Permitir" en el cartel que saldrá a continuación.</p>`,
        icon: "info",
        backdrop: false
      }
      ).then(r => {
        Notification.requestPermission(function (permission) {
          // Si el usuario acepta, lanzamos la notificación
          _subscribeToPushNotifications(reg)
          // WebPush.init()
        });
      });
    } else {
      _subscribeToPushNotifications(reg)
      // WebPush.init()
    }
  },
  registered(reg) {
    console.log('Service worker has been registered.', reg)
    reg.pushManager.getSubscription().then(function (sub) {
      if (sub === null) {
        // Update UI to ask user to register for Push
        console.log('Not subscribed to push service!');
      } else {
        // We have a subscription, update the database
        console.log('Subscription object: ', sub);
      }
    });
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    console.log('New content is downloading.')
    window.location.reload(true)
  },
  updated() {
    console.log('New content is available; please refresh.')
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
})

function _urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function _sendSubscriptionToBackEnd(subscription) {
  localStorage.setItem('subscription', JSON.stringify(subscription))
  return fetch(Axios.defaults.baseURL + '/subscriptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subscription)
  })
    .then(function (response) {
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }
      return response.json();
    })
    .then(function (responseData) {
      if (!responseData.success) {
        throw new Error('Bad response from server.');
      }
    });
}

function _subscribeToPushNotifications(serviceWorker) {
  const subscribeOptions = {
    applicationServerKey: _urlBase64ToUint8Array('BOtMOP07t1DmS4G4plDMKvgKCYg2nL_qrUGQLt8xUkxzquVqkSJenrtN_Ougin3L8yqN5RiXpR0nnFTYi5XHYsE'),
    userVisibleOnly: true
  }
  serviceWorker.pushManager.subscribe(subscribeOptions)
    .then(function (subscription) {
      _sendSubscriptionToBackEnd(subscription)
    })
    .catch(function (e) {
      if (Notification.permission === 'denied') {
        console.warn('Permission for notifications was denied');
      } else {
        console.error('Unable to subscribe to push', e);
      }
    });
}