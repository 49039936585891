const permisoParteRoutes = [
  {
    path: '/permiso-parte',
    name: 'ListPermisoParte',
    component: () =>
      import('@/modules/permiso_parte/views/ListPermisoParte'),
    meta: {
      requiresAuth: true,
      domain: 'PermisoParte',
      title: 'Listar permisos de partes',
      valid: ['RRHH', 'dev'], blockOnDisabled: true,
    },
  },
];

export default permisoParteRoutes;
