<template>
  <div>
    <BlockInterface v-if="loading"></BlockInterface>
    <router-view></router-view>
  </div>
</template>
<script>
import BlockInterface from "@/components/elements/BlockInterface";
import Axios from "axios";
export default {
  name: "App",
  components: {
    BlockInterface,
  },
  data() {
    return {
      requestsCount: 0,
    };
  },
  computed: {
    loading: function () {
      if (this.requestsCount > 0) {
        return true;
      }
      return false;
    },
  },
  created() {
    Axios.interceptors.request.use(
      (config) => {
        this.requestsCount++;
        // trigger 'loading=true' event here
        return config;
      },
      (error) => {
        // trigger 'loading=false' event here

        this.requestsCount--;
        return Promise.reject(error);
      }
    );
    Axios.interceptors.response.use(
      (response) => {
        // trigger 'loading=false' event here

        this.requestsCount--;

        return response;
      },
      (err) => {
        // trigger 'loading=false' event here
        this.requestsCount--;
        if (err.response.status == 401) {
          this.logout();
          return;
        }
        return new Promise(function (resolve, reject) {
          throw err;
        });
      }
    );
  },
  methods: {
    logout() {
      localStorage.setItem("token", null);
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>
